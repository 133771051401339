import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import config from 'react-reveal/globals'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import SEO_VARIABLES from '_config/seo-variables'
import { Navbar, Footer, ViewportObserver, SEO } from '_organisms'
import {
  CookieConsentBar,
  ProudCases,
  HeroSectionCresol,
  AboutCresol,
  ScreensBackground,
  AboutApplicationCresol,
  TestimonialsCresol,
  ResultsCresol,
} from '_molecules'
import { useWindowSize } from '_hooks'
import { AppContext } from '_context'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#testimonials-nav',
}

const CresolPortfolio = ({
  children,
  navbarColorOverride,
  pageTitle,
  pageDescription,
  pageBackgroundImage,
  logoCresol,
  logoCresolDescription,
  textOneSectionOneCresol,
  textTwoSectionOneCresol,
  handAndCellphone,
  handAndCellphoneDescription,
  backgroundSectionOneCresol,
  titleOneSectionTwo,
  textOneSectionTwoCresol,
  largerCircleCresol,
  largerCircleCresolDescription,
  screenPhonesCresol,
  screenPhonesCresolDescription,
  titleTwoSectionTwo,
  textTwoSectionTwoCresol,
  textThreeSectionTwoCresol,
  titleThreeSectionTwo,
  textFourSectionTwoCresol,
  backgroundOneSectionTwoCresol,
  backgroundTwoSectionTwoCresol,
  screenPhonesSectionThree,
  screenPhonesSectionThreeDescription,
  phoneOneSectionFour,
  phoneOneSectionFourDescription,
  titleOneSectionFour,
  textOneSectionFour,
  phoneTwoSectionFour,
  phoneTwoSectionFourDescription,
  titleTwoSectionFour,
  textTwoSectionFour,
  phoneThreeSectionFour,
  phoneThreeSectionFourDescription,
  titleThreeSectionFour,
  textThreeSectionFour,
  backgroundSectionFour,
  testimonials,
  textOneBlockOneResults,
  textOrangeBlockOneResults,
  textTwoBlockOneResults,
  textOneBlockTwoResults,
  textOrangeBlockTwoResults,
  textTwoBlockTwoResults,
  resultTitle,
  orangeStripes,
  orangeStripesDescription,
  storesIcon,
  storesIconDescription,
  downloadIcon,
  downloadIconDescription,
  proudCases,
}) => {
  const { setNavbarDefaults } = useContext(AppContext)
  const { setNavbarColor, setNavbarLight, setCustomBrandColor } = useContext(AppContext)
  const [y, setY] = useState(0)
  config({ ssrFadeout: true })
  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    return setNavbarDefaults
  }, [setNavbarDefaults])

  const [width] = useWindowSize()
  const maxWidth = 992
  const isMobile = width < maxWidth

  return (
    <div className={styles.container}>
      <Navbar navbarColorOverride={navbarColorOverride} opacityBg />
      <SEO
        title={pageTitle || SEO_VARIABLES.title}
        description={pageDescription || SEO_VARIABLES.description}
        thumbnail={pageBackgroundImage}
      />

      <section className={styles.sectionOne}>
        <HeroSectionCresol
          logoCresol={logoCresol}
          logoCresolDescription={logoCresolDescription}
          textOneSectionOneCresol={textOneSectionOneCresol}
          textTwoSectionOneCresol={textTwoSectionOneCresol}
          backgroundSectionOneCresol={backgroundSectionOneCresol}
          handAndCellphone={handAndCellphone}
          handAndCellphoneDescription={handAndCellphoneDescription}
          className={styles.limited}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['cresol-orange'])
            setNavbarLight(!!isMobile)
            setCustomBrandColor(isMobile ? 'dark' : 'light')
          }}
        />
      </section>

      <section className={styles.limited} id="challenge-cresol">
        <AboutCresol
          titleOneSectionTwo={titleOneSectionTwo}
          textOneSectionTwoCresol={textOneSectionTwoCresol}
          largerCircleCresol={largerCircleCresol}
          screenPhonesCresol={screenPhonesCresol}
          titleTwoSectionTwo={titleTwoSectionTwo}
          textTwoSectionTwoCresol={textTwoSectionTwoCresol}
          textThreeSectionTwoCresol={textThreeSectionTwoCresol}
          titleThreeSectionTwo={titleThreeSectionTwo}
          textFourSectionTwoCresol={textFourSectionTwoCresol}
          screenPhonesCresolDescription={screenPhonesCresolDescription}
          largerCircleCresolDescription={largerCircleCresolDescription}
          backgroundOneSectionTwoCresol={backgroundOneSectionTwoCresol}
          backgroundTwoSectionTwoCresol={backgroundTwoSectionTwoCresol}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
            setCustomBrandColor('dark')
          }}
        />
      </section>
      <section className={styles.limited}>
        <ScreensBackground
          screenPhonesSectionThree={screenPhonesSectionThree}
          screenPhonesSectionThreeDescription={screenPhonesSectionThreeDescription}
        />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['cresol-green-dark'])
            setNavbarLight(false)
            setCustomBrandColor('light')
          }}
        />
      </section>
      <section className={styles.limited}>
        <AboutApplicationCresol
          phoneOneSectionFour={phoneOneSectionFour}
          titleOneSectionFour={titleOneSectionFour}
          textOneSectionFour={textOneSectionFour}
          phoneTwoSectionFour={phoneTwoSectionFour}
          titleTwoSectionFour={titleTwoSectionFour}
          textTwoSectionFour={textTwoSectionFour}
          phoneThreeSectionFour={phoneThreeSectionFour}
          titleThreeSectionFour={titleThreeSectionFour}
          textThreeSectionFour={textThreeSectionFour}
          phoneOneSectionFourDescription={phoneOneSectionFourDescription}
          phoneTwoSectionFourDescription={phoneTwoSectionFourDescription}
          phoneThreeSectionFourDescription={phoneThreeSectionFourDescription}
          backgroundSectionFour={backgroundSectionFour}
        />
      </section>
      <section className={styles.limited}>
        <TestimonialsCresol
          TESTIMONIALS_SLIDER_OPTIONS={TESTIMONIALS_SLIDER_OPTIONS}
          testimonials={testimonials}
        />
      </section>
      <section className={styles.limited}>
        <ResultsCresol
          textOneBlockOneResults={textOneBlockOneResults}
          textOrangeBlockOneResults={textOrangeBlockOneResults}
          textTwoBlockOneResults={textTwoBlockOneResults}
          textOneBlockTwoResults={textOneBlockTwoResults}
          textOrangeBlockTwoResults={textOrangeBlockTwoResults}
          textTwoBlockTwoResults={textTwoBlockTwoResults}
          resultTitle={resultTitle}
          orangeStripes={orangeStripes}
          orangeStripesDescription={orangeStripesDescription}
          storesIcon={storesIcon}
          storesIconDescription={storesIconDescription}
          downloadIcon={downloadIcon}
          downloadIconDescription={downloadIconDescription}
        />
      </section>
      <section className={styles.limited}>
        <ProudCases currentProject="CresolPortfolio" caseStudies={proudCases} extendedCTA />
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-primary'])
            setNavbarLight(false)
          }}
        />
      </section>
      {children}
      <CookieConsentBar />
      <Footer />
    </div>
  )
}

CresolPortfolio.propTypes = {
  children: PropTypes.node,
  navbarColorOverride: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  pageBackgroundImage: PropTypes.string.isRequired,
  logoCresol: PropTypes.string.isRequired,
  logoCresolDescription: PropTypes.string.isRequired,
  textOneSectionOneCresol: PropTypes.string.isRequired,
  textTwoSectionOneCresol: PropTypes.string.isRequired,
  handAndCellphone: PropTypes.string.isRequired,
  handAndCellphoneDescription: PropTypes.string.isRequired,
  backgroundSectionOneCresol: PropTypes.string.isRequired,
  titleOneSectionTwo: PropTypes.string.isRequired,
  textOneSectionTwoCresol: PropTypes.string.isRequired,
  largerCircleCresol: PropTypes.string.isRequired,
  largerCircleCresolDescription: PropTypes.string.isRequired,
  screenPhonesCresol: PropTypes.string.isRequired,
  screenPhonesCresolDescription: PropTypes.string.isRequired,
  titleTwoSectionTwo: PropTypes.string.isRequired,
  textTwoSectionTwoCresol: PropTypes.string.isRequired,
  textThreeSectionTwoCresol: PropTypes.string.isRequired,
  titleThreeSectionTwo: PropTypes.string.isRequired,
  textFourSectionTwoCresol: PropTypes.string.isRequired,
  backgroundOneSectionTwoCresol: PropTypes.string.isRequired,
  backgroundTwoSectionTwoCresol: PropTypes.string.isRequired,
  screenPhonesSectionThree: PropTypes.string.isRequired,
  screenPhonesSectionThreeDescription: PropTypes.string.isRequired,
  phoneOneSectionFour: PropTypes.string.isRequired,
  phoneOneSectionFourDescription: PropTypes.string.isRequired,
  titleOneSectionFour: PropTypes.string.isRequired,
  textOneSectionFour: PropTypes.string.isRequired,
  phoneTwoSectionFour: PropTypes.string.isRequired,
  phoneTwoSectionFourDescription: PropTypes.string.isRequired,
  titleTwoSectionFour: PropTypes.string.isRequired,
  textTwoSectionFour: PropTypes.string.isRequired,
  phoneThreeSectionFour: PropTypes.string.isRequired,
  phoneThreeSectionFourDescription: PropTypes.string.isRequired,
  titleThreeSectionFour: PropTypes.string.isRequired,
  textThreeSectionFour: PropTypes.string.isRequired,
  backgroundSectionFour: PropTypes.string.isRequired,
  testimonials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  textOneBlockOneResults: PropTypes.string.isRequired,
  textOrangeBlockOneResults: PropTypes.string.isRequired,
  textTwoBlockOneResults: PropTypes.string.isRequired,
  textOneBlockTwoResults: PropTypes.string.isRequired,
  textOrangeBlockTwoResults: PropTypes.string.isRequired,
  textTwoBlockTwoResults: PropTypes.string.isRequired,
  resultTitle: PropTypes.string.isRequired,
  orangeStripes: PropTypes.string.isRequired,
  orangeStripesDescription: PropTypes.string.isRequired,
  storesIcon: PropTypes.string.isRequired,
  storesIconDescription: PropTypes.string.isRequired,
  downloadIcon: PropTypes.string.isRequired,
  downloadIconDescription: PropTypes.string.isRequired,
  proudCases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
CresolPortfolio.defaultProps = {
  children: undefined,
  navbarColorOverride: undefined,
}

export default CresolPortfolio
