import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { AppContext } from '_context'
import CresolPortfolio from '_components/templates/portfolio-content/cresol'
import variables from '_config/css-variables'

const CresolCaseStudy = ({ data }) => {
  const content = data.contentfulCresolPay
  const { setNavbarColor, setNavbarLight, setCustomBrandColor } = useContext(AppContext)

  useEffect(() => {
    setNavbarColor(variables['cresol-primary'])
    setNavbarLight(false)
    setCustomBrandColor('dark')
  }, [setNavbarColor, setNavbarLight, setCustomBrandColor])
  return (
    <div>
      <CresolPortfolio
        navbarColorOverride={variables['cresol-primary']}
        pageTitle={content.pageTitle}
        pageDescription={content.pageDescription}
        pageBackgroundImage={content.pageBackgroundImage.file.url}
        logoCresol={content.logoCresol.file.url}
        logoCresolDescription={content.logoCresol.description}
        textOneSectionOneCresol={content.textOneSectionOneCresol.internal.content}
        textTwoSectionOneCresol={content.textTwoSectionOneCresol.internal.content}
        arrowCresol={content.arrowCresol.file.url}
        arrowCresolDescription={content.arrowCresol.description}
        handAndCellphone={content.handAndCellphone.file.url}
        backgroundSectionOneCresol={content.backgroundSectionOneCresol.file.url}
        titleOneSectionTwo={content.titleOneSectionTwo}
        textOneSectionTwoCresol={content.textOneSectionTwoCresol.internal.content}
        largerCircleCresol={content.largerCircleCresol.file.url}
        largerCircleCresolDescription={content.largerCircleCresol.description}
        screenPhonesCresol={content.screenPhonesCresol.file.url}
        screenPhonesCresolDescription={content.screenPhonesCresol.description}
        titleTwoSectionTwo={content.titleTwoSectionTwo}
        textTwoSectionTwoCresol={content.textTwoSectionTwoCresol}
        textThreeSectionTwoCresol={content.textThreeSectionTwoCresol}
        titleThreeSectionTwo={content.titleThreeSectionTwo}
        textFourSectionTwoCresol={content.textFourSectionTwoCresol}
        backgroundOneSectionTwoCresol={content.backgroundOneSectionTwoCresol.file.url}
        backgroundTwoSectionTwoCresol={content.backgroundTwoSectionTwoCresol.file.url}
        screenPhonesSectionThree={content.screenPhonesSectionThree.file.url}
        screenPhonesSectionThreeDescription={content.screenPhonesSectionThree.description}
        phoneOneSectionFour={content.phoneOneSectionFour.file.url}
        phoneOneSectionFourDescription={content.phoneOneSectionFour.description}
        titleOneSectionFour={content.titleOneSectionFour}
        textOneSectionFour={content.textOneSectionFour.internal.content}
        phoneTwoSectionFour={content.phoneTwoSectionFour.file.url}
        phoneTwoSectionFourDescription={content.phoneTwoSectionFour.description}
        titleTwoSectionFour={content.titleTwoSectionFour}
        textTwoSectionFour={content.textTwoSectionFour.internal.content}
        phoneThreeSectionFour={content.phoneThreeSectionFour.file.url}
        phoneThreeSectionFourDescription={content.phoneThreeSectionFour.description}
        titleThreeSectionFour={content.titleThreeSectionFour}
        textThreeSectionFour={content.textThreeSectionFour.internal.content}
        backgroundSectionFour={content.backgroundSectionFour.file.url}
        testimonials={content.testimonials}
        textOneBlockOneResults={content.textOneBlockOneResults}
        textOrangeBlockOneResults={content.textOrangeBlockOneResults}
        textTwoBlockOneResults={content.textTwoBlockOneResults}
        textOneBlockTwoResults={content.textOneBlockTwoResults}
        textOrangeBlockTwoResults={content.textOrangeBlockTwoResults}
        textTwoBlockTwoResults={content.textTwoBlockTwoResults}
        resultTitle={content.resultTitle}
        orangeStripes={content.orangeStripes.file.url}
        orangeStripesDescription={content.orangeStripes.description}
        storesIcon={content.storesIcon.file.url}
        storesIconDescription={content.storesIcon.description}
        downloadIcon={content.downloadIcon.file.url}
        downloadIconDescription={content.downloadIcon.description}
        proudCases={content.proudCases}
      />
    </div>
  )
}

export const query = graphql`
  query contentfulCresolPay($locale: String) {
    contentfulCresolPay(fields: { localeKey: { eq: $locale } }) {
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      orangeStripes {
        description
        file {
          url
        }
      }
      pageTitle
      pageDescription
      pageBackgroundImage {
        description
        file {
          url
        }
      }
      logoCresol {
        description
        file {
          url
        }
      }
      arrowCresol {
        description
        file {
          url
        }
      }
      handAndCellphone {
        description
        file {
          url
        }
      }
      textOneSectionOneCresol {
        internal {
          content
        }
      }
      textTwoSectionOneCresol {
        internal {
          content
        }
      }
      backgroundSectionOneCresol {
        description
        file {
          url
        }
      }
      titleOneSectionTwo
      textOneSectionTwoCresol {
        internal {
          content
        }
      }
      largerCircleCresol {
        description
        file {
          url
        }
      }
      screenPhonesCresol {
        description
        file {
          url
        }
      }
      titleTwoSectionTwo
      textTwoSectionTwoCresol
      textThreeSectionTwoCresol
      titleThreeSectionTwo
      textFourSectionTwoCresol
      backgroundOneSectionTwoCresol {
        description
        file {
          url
        }
      }
      backgroundTwoSectionTwoCresol {
        description
        file {
          url
        }
      }
      screenPhonesSectionThree {
        description
        file {
          url
        }
      }
      phoneOneSectionFour {
        description
        file {
          url
        }
      }
      titleOneSectionFour
      textOneSectionFour {
        internal {
          content
        }
      }
      phoneTwoSectionFour {
        description
        file {
          url
        }
      }
      titleTwoSectionFour
      textTwoSectionFour {
        internal {
          content
        }
      }
      phoneThreeSectionFour {
        description
        file {
          url
        }
      }
      titleThreeSectionFour
      textThreeSectionFour {
        internal {
          content
        }
      }
      backgroundSectionFour {
        description
        file {
          url
        }
      }
      titleSectionFive
      imageProfileSectionFive {
        description
        file {
          url
        }
      }
      namePersonSectionFive
      descriptionPersonSectionFive
      textOneBlockOneResults
      textOrangeBlockOneResults
      textTwoBlockOneResults
      textOneBlockTwoResults
      textOrangeBlockTwoResults
      textTwoBlockTwoResults
      resultTitle
      storesIcon {
        description
        file {
          url
        }
      }
      downloadIcon {
        description
        file {
          url
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

CresolCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default CresolCaseStudy
